import React from 'react'
import styled from 'styled-components'

import Nav from '../components/nav'
import Footer from '../components/footer'
import SEO from '../components/seo'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const Terms = () => {
  useHotjarScript()
  return (
    <>
      <Nav theme="light" />
      <SEO
        title="Privacy Policy - Pulse"
        description="Master asynchronous communication to increase your team communication bandwidth, stay more connected and aligned by communicating more openly and asynchronously."
      />
      <Container>
        <Body>
          Thank you for using Pulse. These Terms of Service (the “Terms”) and
          our Privacy Policy (at{' '}
          <a href="https://pulseasync.com/terms-of-service">
            https://pulseasync.com/terms-of-service
          </a>
          ) govern your use of Pulse, so please read them carefully before using
          Pulse.
        </Body>
        <Body>
          These Terms of Service (the “Terms”) describe your legal rights and
          responsibilities when using the online communication systems,
          productivity tools and services provided by Sametab, Inc. (the
          “Services”). These Terms create a legal and binding agreement between
          Sametab, Inc. (“Pulse” or “we”) and the Customer (as further described
          below) agreeing to be bound by these Terms. A Customer may be referred
          to herein as the “Customer” or as “you.” Those persons who access the
          Services using Customer’s account are referred to herein as the “End
          Users.”
        </Body>
        <Body>
          By using Pulse, you agree to be bound by these Terms. If you don’t
          agree to these Terms, do not use Pulse. If you are using Pulse on
          behalf of an organization (such as your employer), you are agreeing to
          these Terms for that organization, and are indicating that you have
          the authority to bind that organization to these Terms. In that case,
          “you” and “your” will refer to that organization.
        </Body>
        <Body>
          We may revise the Terms from time to time. If a revision is material,
          as determined solely by us, we will notify you (for example via email
          to the email address associated with your account). Other changes may
          be posted to our blog, so please check that regularly. The most
          current version will always be posted on our Terms page. By continuing
          to use Pulse after revisions become effective, you agree to be bound
          by the revised Terms. If you do not agree to the new Terms, please
          stop using Pulse.
        </Body>
        <H1>Your Information and Responsibilities</H1>
        <Body>
          To use Pulse, you’ll need to create an account, either via Pulse or
          through your account with a third-party service such as Google. In the
          latter case, your Pulse account will be created using the information
          you provided to that service, such as your name and email address and
          other personal information that your privacy settings on that service
          permit us to access.
        </Body>
        <Body>
          You may use Pulse only if you are 13 years or older and are not barred
          from using Pulse under applicable law.
        </Body>
        <Body>
          You are responsible for safeguarding the password that you use to
          access Pulse. You are responsible for any activity on your account,
          whether or not you authorized that activity. You should immediately
          notify Pulse of any unauthorized use of your account.
        </Body>
        <Body>
          By using Pulse, you provide us with text, graphics, images and other
          information (“your content”). You retain full ownership to your
          content. Pulse does not claim any ownership rights to your content.
          However, you are also solely responsible for your content. You
          indicate that you own or have the necessary rights to all of your
          content, and that use of your content does not infringe,
          misappropriate or violate a third party’s intellectual property
          rights, or rights of publicity or privacy, or result in the violation
          of any applicable law or regulation.
        </Body>
        <Body>
          You can remove your content by deleting it. However, in certain
          instances, some of your content (such as documents created by others
          that you have contributed to) may not be completely removed and copies
          of your content may continue to exist on Pulse. We’re not responsible
          or liable for the removal or deletion of (or the failure to remove or
          delete) any of your content.
        </Body>
        <H1>Pulse Intellectual Property Rights</H1>
        <Body>
          Pulse and its licensors exclusively own Pulse, including all
          associated intellectual property rights. You acknowledge that Pulse is
          protected by copyright, trademark, and other laws of the United States
          and foreign countries. You agree not to remove, alter or obscure any
          copyright, trademark, service mark or other proprietary rights or
          notices incorporated in or accompanying Pulse.
        </Body>
        <Body>
          Pulse grants you a limited, non-exclusive, non-transferable license to
          view, copy, and display Pulse solely in connection with your permitted
          use of Pulse.
        </Body>

        <H2>Your Data</H2>
        <Body>
          You retain full ownership of all content you submit to the Services
          (your “Data”). We do not claim any ownership to any of it.
        </Body>
        <Body>
          We will use, at a minimum, industry standard technical and
          organizational security measures to transfer, store, and process your
          Data. These measures are designed to protect the integrity of your
          Data and guard against the unauthorized or unlawful access to, use,
          and processing of your Data. However, we will not be liable for any
          loss or corruption of your Data caused by you or others you have
          granted access to your Data, or for any costs or expenses associated
          with backing up or restoring any of your Data.
        </Body>
        <Body>
          You agree that we may transfer, store, and process your Data in
          locations other than your country.
        </Body>
        <H2>Your Use of the Services</H2>
        <Body>
          You are solely responsible for your conduct, your Data, who you grant
          access to your Data and your communications with others while using
          the Services. You will not, and will not attempt to, use the Services
          to violate this Agreement, any laws, any of our rights or any rights
          of another person. You will promptly notify us of any unauthorized use
          or access to the Services.
        </Body>
        <H2>Third-Party Services</H2>
        <Body>
          If you use any third-party service (e.g., a service that uses the
          Pulse API) with the Services, we will not be responsible for any act
          or omission of the third party, including the third party’s access to
          or use of your Data. We do not warrant or support any service provided
          by the third party.
        </Body>
        <H2>Payment</H2>

        <Body>
          If you use the Services for business purposes, we require that you
          purchase a license to do so. You may purchase a license via credit
          card through our business portal (self-serve payment portal) or email
          us at sales@pulse.so to inquire about other payment methods. You
          authorize us to charge your selected payment method for all applicable
          fees, including all taxes, related to the required license. You are
          responsible for providing complete and accurate billing and contact
          information to us.
        </Body>
        <Body>
          IF YOUR ACCOUNT IS SET TO AUTO RENEWAL, WE MAY AUTOMATICALLY CHARGE AT
          FOR THE RENEWAL, UNLESS YOU NOTIFY US THAT YOU WANT TO CANCEL OR
          DISABLE AUTO RENEWAL.
        </Body>
        <Body>
          We may revise Service rates, for example, to increase your number of
          licensed seats or to charge for additional features. We will provide
          you with notice of any increase.
        </Body>
        <Body>
          We may suspend or terminate the Services if fees are past due. Fees
          are non-refundable except as required by law.
        </Body>

        <H2>Customer List</H2>
        <Body>
          We may disclose on our website or other promotional materials that you
          use our Services and may include your company name or logo or both in
          connection with such materials.
        </Body>

        <H2>Changes to the Services</H2>
        <Body>
          The Services will continue to evolve as we refine features and
          functionality. If we change the Services in a manner that materially
          reduces their functionality, we will inform you.
        </Body>

        <H2>Third Party Requests</H2>
        <Body>
          You are responsible for responding to any requests from a third party
          for records relating to your use of the Services (“Third Party
          Requests”), including subpoenas, court orders, valid search warrants
          or any other requests. You are responsible for responding to Third
          Party Requests via your own access to information. You will contact us
          only if you cannot obtain such information after diligent efforts.
        </Body>
        <Body>
          We will make commercially reasonable efforts, to the extent allowed by
          law and by the terms of the Third Party Request, to promptly notify
          you of a Third Party Request we have received; to comply with your
          commercially reasonable requests regarding efforts to oppose the Third
          Party Request; and to provide you with information you require to
          respond to the Third Party Request (if you are otherwise unable to
          obtain the information after diligent efforts). If you fail to
          promptly respond to any Third Party Request, then we may, but will not
          be obligated, to respond.
        </Body>
        <H2>General Prohibitions</H2>

        <Body>You agree not to do—or attempt to do—any of the following:</Body>
        <Body>
          <ul>
            <li>
              Probe, scan, or test the vulnerability of any Pulse system or
              network or breach any security or authentication measures;
            </li>
            <li>
              Access, tamper with, or use non-public areas of Pulse, Pulse’s
              computer systems, or the technical delivery systems of Pulse’s
              providers;
            </li>
            <li>
              Decipher, decompile, disassemble or reverse engineer any of the
              software used to provide Pulse;
            </li>
            <li>
              Interfere with, or attempt to interfere with, the access of any
              user, host or network, including sending a virus, overloading,
              flooding, spamming, or mail-bombing Pulse;
            </li>
            <li>
              Access or search Pulse or download any intellectual property from
              Pulse through the use of any engine, software, tool, agent, device
              or mechanism (including spiders, robots, crawlers, data mining
              tools or the like) other than our publicly supported interfaces;
            </li>
            <li>Plant malware or use Pulse to distribute malware;</li>
            <li>
              Send any unsolicited communications, promotions, advertisements or
              spam;
            </li>
            <li>
              Send altered, deceptive or false source-identifying information,
              including “spoofing” or “phishing”;
            </li>
            <li>
              Post or transmit anything that is fraudulent or misleading, or
              that infringes on others’ rights;
            </li>
            <li>
              Impersonate or misrepresent your affiliation with any person or
              entity;
            </li>
            <li>Violate the privacy of others;</li>
            <li>Violate any applicable law or regulation; or</li>
            <li>
              Encourage or enable any other individual to do any of the above.
            </li>
          </ul>
        </Body>
        <Body>
          Although we’re not obligated to monitor access to or use of Pulse or
          your content or to review or edit any of your content or the
          intellectual property of other Pulse users, we have the right to do so
          for the purpose of operating Pulse, to ensure compliance with these
          Terms, or to comply with applicable law or other legal requirements.
          We reserve the right, but are not obligated, to remove or disable
          access to any of your content, at any time and without notice,
          including, but not limited to, if we, at our sole discretion, consider
          any of your content to be objectionable or in violation of these
          Terms. We have the right to investigate violations of these Terms or
          conduct that affects Pulse. We may also consult and cooperate with law
          enforcement authorities to prosecute users who violate the law.
        </Body>
        <H1>Termination</H1>
        <Body>
          We may suspend Pulse or terminate your access to and use of Pulse, at
          our sole discretion, at any time and without notice to you. For
          example, we may suspend or terminate your use if you are not complying
          with these Terms, or use Pulse in any way that would cause us legal
          liability or disrupt others’ use of Pulse. If we suspend or terminate
          your use, we will try to let you know in advance and help you retrieve
          data, though there may be some cases (for example, repeatedly or
          flagrantly violating these Terms, a court order, or danger to other
          users) where we may suspend immediately. You may cancel your account
          at any time by sending an email to legal@pulse.so.
        </Body>
        <H1>Warranty Disclaimers</H1>
        <Body>
          PULSE OR OUR LICENSORS’ INTELLECTUAL PROPERTY ARE PROVIDED “AS IS,”
          WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE
          EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT AND ANY
          WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. WE MAKE
          NO WARRANTY THAT PULSE WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON
          AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY
          REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS,
          COMPLETENESS OR RELIABILITY OF ANY OF OUR INTELLECTUAL PROPERTY.
        </Body>
        <H1>Indemnity</H1>
        <Body>
          You will indemnify and hold harmless Pulse and its officers,
          directors, employees and agents, from and against any claims,
          disputes, demands, liabilities, damages, losses, and costs and
          expenses, including, without limitation, reasonable legal and
          accounting fees, arising out of or in any way connected with (i) your
          access to or use of Pulse or our licensors’ intellectual property;
          (ii) your content; or (iii) your violation of these Terms.
        </Body>
        <H1>Limitation of Liability</H1>
        <Body>
          NEITHER PULSE NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR
          DELIVERING PULSE, INCLUDING OUR LICENSORS, WILL BE LIABLE FOR ANY
          INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING
          LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER
          DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES ARISING
          OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR
          INABILITY TO USE PULSE OR OUR LICENSORS’ INTELLECTUAL PROPERTY,
          WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
          PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT PULSE
          HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED
          REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
          PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
          OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE
          LIMITATION MAY NOT APPLY TO YOU.
        </Body>
        <Body>
          IN NO EVENT WILL PULSE’s TOTAL LIABILITY ARISING OUT OF OR IN
          CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE
          PULSE OR TO ACCESS YOUR CONTENT EXCEED THE AMOUNTS YOU HAVE PAID TO
          PULSE FOR USE OF PULSE OR TWENTY DOLLARS ($20), IF YOU HAVE NOT HAD
          ANY PAYMENT OBLIGATIONS TO PULSE, AS APPLICABLE. THE LIMITATIONS OF
          DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE
          BARGAIN BETWEEN PULSE AND YOU.
        </Body>
        <H1>Dispute Resolution</H1>
        <H1>Governing Law</H1>
        <Body>
          These Terms and any action related thereto will be governed by the
          laws of the State of California without regard to its conflict of laws
          provisions.
        </Body>
        <H1>Agreement to Arbitrate</H1>
        <Body>
          You and Pulse agree that any dispute, claim or controversy arising out
          of or relating to these Terms or the breach, termination, enforcement,
          interpretation or validity thereof or the use of Pulse (collectively,
          “Disputes”) will be settled by binding arbitration, except that each
          party retains the right: (i) to bring an individual action in small
          claims court and (ii) to seek injunctive or other equitable relief in
          a court of competent jurisdiction to prevent the actual or threatened
          infringement, misappropriation or violation of a party’s copyrights,
          trademarks, trade secrets, patents or other intellectual property
          rights (the action described in the foregoing clause (ii), an “IP
          Protection Action”). The exclusive jurisdiction and venue of any IP
          Protection Action will be the state and federal courts located in the
          Northern District of California and each of the parties hereto waives
          any objection to jurisdiction and venue in such courts.{' '}
          <strong>
            You acknowledge and agree that you and Pulse are each waiving the
            right to a trial by jury or to participate as a plaintiff or class
            member in any purported class action or representative proceeding.
          </strong>{' '}
          Further, unless both you and Pulse otherwise agree in writing, the
          arbitrator may not consolidate more than one person’s claims, and may
          not otherwise preside over any form of any class or representative
          proceeding. If this specific paragraph is held unenforceable, then the
          entirety of this “Dispute Resolution” section will be deemed void.
          Except as provided in the preceding sentence, this “Dispute
          Resolution” section will survive any termination of these Terms.
        </Body>

        <H1>Arbitration Rules</H1>
        <Body>
          The arbitration will be administered by the American Arbitration
          Association (“AAA”) in accordance with the Commercial Arbitration
          Rules and the Supplementary Procedures for Consumer Related Disputes
          (the “AAA Rules”) then in effect, except as modified by this “Dispute
          Resolution” section. (The AAA Rules are available at&nbsp;
          <a href="https://www.adr.org/arb_med">www.adr.org/arb_med</a> or by
          calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will
          govern the interpretation and enforcement of this Section.
        </Body>

        <H1>Arbitration Process</H1>
        <Body>
          A party who desires to initiate arbitration must provide the other
          party with a written Demand for Arbitration as specified in the AAA
          Rules. (The AAA provides a form Demand for Arbitration at&nbsp;
          <a href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175">
            http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175
          </a>
          &nbsp;and a separate form for California residents at &nbsp;
          <a href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822">
            http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822
          </a>
          .) The arbitrator will be either a retired judge or an attorney
          licensed to practice law and will be selected by the parties from the
          AAA’s roster of arbitrators. If the parties are unable to agree upon
          an arbitrator within seven (7) days of delivery of the Demand for
          Arbitration, then the AAA will appoint the arbitrator in accordance
          with the AAA Rules.
        </Body>

        <H1>Arbitration Location and Procedure</H1>
        <Body>
          Unless you and Pulse otherwise agree, the arbitration will be
          conducted in the county where you reside. If your claim does not
          exceed $10,000, then the arbitration will be conducted solely on the
          basis of the documents that you and Pulse submit to the arbitrator,
          unless you request a hearing or the arbitrator determines that a
          hearing is necessary. If your claim exceeds $10,000, your right to a
          hearing will be determined by the AAA Rules. Subject to the AAA Rules,
          the arbitrator will have the discretion to direct a reasonable
          exchange of information by the parties, consistent with the expedited
          nature of the arbitration.
        </Body>

        <H1>Arbitrator’s Decision</H1>
        <Body>
          The arbitrator will render an award within the time frame specified in
          the AAA Rules. The arbitrator’s decision will include the essential
          findings and conclusions upon which the arbitrator based the award.
          Judgment on the arbitration award may be entered in any court having
          jurisdiction thereof. The arbitrator’s award of damages must be
          consistent with the terms of the “Limitation of Liability” section
          above as to the types and amounts of damages for which a party may be
          held liable. The arbitrator may award declaratory or injunctive relief
          only in favor of the claimant and only to the extent necessary to
          provide relief warranted by the claimant’s individual claim. If you
          prevail in arbitration you will be entitled to an award of attorneys’
          fees and expenses, to the extent provided under applicable law. Pulse
          will not seek, and hereby waives all rights it may have under
          applicable law to recover, attorneys’ fees and expenses if it prevails
          in arbitration.
        </Body>

        <H1>Fees</H1>
        <Body>
          Your responsibility to pay any AAA filing, administrative and
          arbitrator fees will be solely as set forth in the AAA Rules. However,
          if your claim for damages does not exceed $75,000, Pulse will pay all
          such fees unless the arbitrator finds that either the substance of
          your claim or the relief sought in your Demand for Arbitration was
          frivolous or was brought for an improper purpose (as measured by the
          standards set forth in Federal Rule of Civil Procedure 11(b)).
        </Body>

        <H1>Changes</H1>
        <Body>
          Notwithstanding the provisions of the “Modification” section above, if
          Pulse changes this “Dispute Resolution” section after the date you
          first accepted these Terms (or accepted any subsequent changes to
          these Terms), you may reject any such change by sending us written
          notice (including by email to legal@pulse.so) within 30 days of the
          date such change became effective, as indicated in the “Last Updated”
          date above or in the date of Pulse’s email to you notifying you of
          such change. By rejecting any change, you are agreeing that you will
          arbitrate any Dispute between you and Pulse in accordance with the
          provisions of this “Dispute Resolution” section as of the date you
          first accepted these Terms (or accepted any subsequent changes to
          these Terms).
        </Body>

        <H1>General Terms</H1>
        <Body>
          These Terms constitute the entire and exclusive understanding and
          agreement between Pulse and you regarding Pulse, and these Terms
          supersede and replace any and all prior oral or written understandings
          or agreements between Pulse and you regarding Pulse, except that if
          you become a party to Pulse’s Business Agreement, either before or
          after reviewing these Terms, the terms and conditions of the Business
          Agreement will govern over any conflicting provisions herein. If for
          any reason a court of competent jurisdiction finds any provision of
          these Terms invalid or unenforceable, that provision will be enforced
          to the maximum extent permissible and the other provisions of these
          Terms will remain in full force and effect.
        </Body>
        <Body>
          You may not assign or transfer these Terms, by operation of law or
          otherwise, without Pulse’s prior written consent. Any attempt by you
          to assign or transfer these Terms, without such consent, will be null
          and of no effect. Pulse may freely assign or transfer these Terms
          without restriction. Subject to the foregoing, these Terms will bind
          and inure to the benefit of the parties, their successors and
          permitted assigns.
        </Body>
        <Body>
          Any notices or other communications provided by Pulse under these
          Terms, including those regarding modifications to these Terms, will be
          given: (i) by Pulse via email; or (ii) by posting to our website. For
          notices made by e-mail, the date of receipt will be deemed the date on
          which such notice is transmitted.
        </Body>
        <Body>
          Pulse’s failure to enforce any right or provision of these Terms will
          not be considered a waiver of those rights. The waiver of any such
          right or provision will be effective only if in writing and signed by
          a duly authorized representative of Pulse. Except as expressly set
          forth in these Terms, the exercise by either party of any of its
          remedies under these Terms will be without prejudice to its other
          remedies under these Terms or otherwise.
        </Body>
        <H1>Contact Information</H1>
        <Body>
          If you have any questions about these Terms, please contact us at:
          <br />
          Sametab, Inc <br />
          405 Howard Street <br />
          San Francisco, CA 94105, United States
          <br />
          <a href="mailto:legal@pulse.so">legal@pusle.so</a>
        </Body>
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.main`
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 43.75rem;
  max-width: 92.5vw;
  margin: auto;
`

const Article = styled.article`
  width: 43.75rem;
  max-width: 95vw;
  margin: auto;
  padding-top: 2rem;
`

const H1 = styled.h1`
  font-family: ${props => props.theme.typography.fontFamilySuisseIntl};
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2.6875rem;
  letter-spacing: 0.0625rem;
`

const H2 = styled.h2`
  font-family: ${props => props.theme.typography.fontFamilySuisseIntl};
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2.125rem;
  color: #000000;
  margin: 2rem 0rem;
`

const Body = styled.p`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 2.125rem;
  color: #545454;
  margin: 2rem 0rem;
  a {
    color: blue;
    text-decoration: none;
  }
`

export default Terms
